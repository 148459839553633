/* todo: delete file if not needed */
.section-holder {
  overflow: hidden;
  display: flex;
  // height: 100%;
  width: 100%;
  padding: 0px 10px;
  cursor: grab;
}
.section-holder:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.section-holder::-webkit-scrollbar {
  display: none;
}
.section {
  align-self: center;
  justify-self: center;
  align-items: center;
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.panel {
  display: flex;
  align-self: center;
  height: 20rem;
  width: 80%;
  background-color: rgba(255, 255, 255, 0.08);
  // margin: 40px;
  border-radius: 10px;
}
.product-category-title {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #9b9b9b10;
  // height: 100%;
  width: 80px;
  font-size: 30px;
  border-radius: 10px 0px 0px 10px;
}
.product-category-title h1 {
  transform: rotate(-90deg);
}
