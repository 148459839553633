$containerWidth: 200px;
$borderRadius: 10px;

.glossyContainer {
  display: flex;
  flex-direction: column;
  width: $containerWidth;
  min-width: $containerWidth;
  height: 80%;
  border-radius: $borderRadius;
  background: linear-gradient(135deg, #fafffe81, #362f2fa6, #fafffeb0);
  background-size: 350% 350%;
  -webkit-box-shadow: 5px 5px 8px 5px #000000;
  box-shadow: 3px 5px 15px 2px #46464655;
  justify-content: space-between;

  // transition info
  transition: all 0.2s ease-in-out;
  margin: 10px 50px;
}

.glossyContainer:hover {
  transform: scale(1.05);
  animation: product-hover 4s ease forwards;
  cursor: pointer;
}

.glossyContainer:active {
  cursor: grabbing;
}

.image {
  align-self: center;
  margin: 0px;
  height: 80%;
  width: 100%;
}

.image img {
  height: 100%;
  width: 100%;
  border-radius: $borderRadius $borderRadius 0 0;
  pointer-events: none;
}
.title-holder {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}
.title {
  align-self: center;
  color: white;
  font-weight: bold;
  max-height: 52px;
  // max-font-size: 1.4rem;
  margin: 0rem 0.5rem;
  font-size: max(1.4rem);
  text-align: center;
}

@-webkit-keyframes product-hover {
  0% {
    background-position: 90% 0%;
  }
  50% {
    background-position: 11% 100%;
  }
  100% {
    background-position: 90% 0%;
  }
}
@-moz-keyframes product-hover {
  0% {
    background-position: 90% 0%;
  }
  50% {
    background-position: 11% 100%;
  }
  100% {
    background-position: 90% 0%;
  }
}
@keyframes product-hover {
  0% {
    background-position: 20% 20%;
  }
  // 50%{background-position:11% 100%}
  100% {
    background-position: 90% 0%;
  }
}
