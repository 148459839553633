.welcome-title {
  display: flex;
  flex-direction: column;
  background-color: #c0c0c03d;
  font-size: 5rem;
  color: white;
  z-index: 1;
  align-items: center;
  // padding: 2rem;
  border-radius: 1 rem;
  // margin: 5rem;
}
.welcome-brief {
  font-size: 5rem;
  z-index: 1;
  color: white;
  display: flex;
  flex-direction: column;
  background-color: #c0c0c03d;
  border-radius: 1 rem;
  text-align: right;
  // margin: 5rem;
  // padding: 2rem;
}

.front-panel {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.layer-home {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100vh;
  // width: 98vw;
  z-index: 2;
  scroll-snap-align: start;
  // text-align: center;
  // color: white;
  // font-size: 7rem;
}
