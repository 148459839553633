.App {
  user-select: none;
}

html {
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
}
.mainScreen {
  display: flex;
  flex-direction: column;
  margin: 0px;
  background-image: url("starryBackground.jpeg");
  background-color: black;
}

.parallax {
  /* The image used */
  display: flex;
  user-select: none;
  /* Set a specific height */
  /* height: 98vh; */
  /* width: 98vw; */
  /* overflow: hidden; */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.flex-placeholder {
  display: flex;
  flex-grow: 2;
  z-index: 1;
}

.parallax-video {
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Insert Scrolling Box */
.scrollBox {
  display: flex;
  flex-direction: column;
  z-index: 2;
  width: 100%;
  /* height: 200%; */
}
