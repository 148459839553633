/* todo: delete file if not needed */
.product-categories {
  display: flex;
  flex-direction: column;
  scroll-snap-align: start;
  height: 100vh;
  // width: 99vw;
  background-color: rgba(255, 255, 255, 0.1);
  justify-content: space-around;
  align-items: space-between;

  //Rave
  // animation: 12s infinite bgcolorchange;
}

@keyframes bgcolorchange {
  0% {
    background-color: rgba(0, 0, 0, 0.3);
  }
  10% {
    background-color: rgba(53, 37, 37, 0.3);
  }
  20% {
    background-color: rgba(12, 7, 61, 0.3);
  }
  30% {
    background-color: rgba(14, 88, 39, 0.3);
  }
  40% {
    background-color: rgba(77, 76, 21, 0.3);
  }
  50% {
    background-color: rgba(24, 8, 61, 0.3);
  }
  60% {
    background-color: rgba(255, 17, 17, 0.3);
  }
  70% {
    background-color: rgba(8, 255, 41, 0.3);
  }
  80% {
    background-color: rgba(238, 255, 0, 0.3);
  }
  90% {
    background-color: rgba(121, 11, 102, 0.3);
  }
  100% {
    background-color: rgba(1, 1, 1, 0.3);
  }
}
