/* todo: delete file if not needed */
.layer-ux {
  display: flex;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 3;
}

.next-image img:hover {
  transform: rotate(90deg) scale(1.5);
}

.next-image {
  position: absolute;
  display: flex;
  width: 100%;
  height: 10%;
  z-index: 3;
  justify-content: center;
  bottom: 0;
}

.next-image img {
  max-width: 100%;
  max-height: 100%;
  transform: rotate(90deg);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.image-stretcher {
  text-align: center;
}
